/* You can add global styles to this file, and also import other style files */
@import "variables";
@import "mat-theme";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: "IBM Plex Sans Arabic", sans-serif;
  direction: rtl;
}

html,
body {
  height: 100%;
  direction: rtl;

  scrollbar-color: var(--Core-Content-Tertiary);
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 4px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  &::-webkit-scrollbar-thumb {
    background: var(--Core-Content-Tertiary);
    border-radius: 4px;
  }
}
body {
  margin: 0;
  font-family: "IBM Plex Sans Arabic", sans-serif;
  background: var(--Foundation-PrimaryB);
  width: 100vw;
  overflow-x: hidden;
}

body.no-scroll {
  overflow: hidden;
}

img {
  width: 100%;
}
a {
  color: inherit;
  text-decoration: none;
}

.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.d-flex {
  display: flex;
  align-items: center;
}

.primary-btn {
  border-radius: 0.75rem !important;
  background: var(--Foundation-PrimaryA) !important;
  border-color: var(--Foundation-PrimaryA) !important;
  /* Shadow/Medium */
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06) !important;
  color: var(--Foundation-PrimaryB) !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  line-height: 150% !important; /* 1.5rem */
  &:hover {
    background: var(--Extensions-Background-Brand) !important;
    -webkit-transition: background-color 0.2s linear;
    -ms-transition: background-color 0.2s linear;
    transition: background-color 0.2s linear;
  }

  @media (max-width: 768px) {
    border-radius: 0.625rem;
  }
}

.heading-1 {
  font-size: 3.5rem;
  font-weight: 500;
  line-height: 140%; /* 3rem */
  @media (max-width: 768px) {
    font-size: 1.75rem;
    line-height: 150%; /* 2.625rem */
  }
}

.heading-7 {
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 140%; /* 3rem */
  text-align: center;
  @media (max-width: 768px) {
    font-size: 1.5rem;
    line-height: 150%; /* 2.625rem */
  }
}

.heading-3 {
  color: var(--Core-Content-Primary);
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 120%; /* 3rem */
  text-align: center;
  @media (max-width: 768px) {
    font-size: 1.75rem;
    line-height: 150%; /* 2.625rem */
  }
}

.heading-6 {
  color: var(--Foundation-PrimaryA);
  text-align: center;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 140%; /* 1.75rem */
}

.heading-5 {
  color: var(--Core-Content-Primary);
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 140%; /* 1.75rem */
  @media (max-width: 768px) {
    font-size: 1.25rem !important;
  }
}

.text-medium {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

.text-small {
  @extend .text-medium;
  line-height: 1.6rem !important;
}

.text-tiny {
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.text-bold {
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.field {
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
  font-weight: normal;
  margin-bottom: 1rem;

  &__label {
    color: var(--Core-Content-Primary);
    font-size: 1rem;
    font-weight: 500;
    line-height: 150%; /* 1.5rem */
    margin-bottom: 0.5rem;
    height: fit-content;

    &__required {
      color: var(--Extensions-Background-Danger);
      font-size: 1rem;
    }
    @media (max-width: 768px) {
      margin-bottom: 0.375rem;
    }
  }

  &.field-check,
  &.field-radio,
  &.field-boolean {
    margin-bottom: 2.5rem;
  }

  &__alert-message {
    color: var(--Extensions-Background-Danger);
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%; /* 1.5rem */
    margin-top: 0.5rem;
  }

  &__hint-message {
    color: var(--Core-Content-Secondary);
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%; /* 1.5rem */
    margin-top: 0.5rem;
  }

  mat-icon {
    color: var(--Core-Content-Secondary);
    // height: 1rem;
    // width: 1rem;
    // font-size: 1rem;
    position: relative;
    // bottom: 0.5rem;
    cursor: pointer;

    &.active {
      color: var(--Core-Content-Secondary);
    }
  }

  &.field-file {
    .upload-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        font-size: 1.8rem;
        color: #55636d;
      }

      button {
        width: 15rem;
        height: 4rem;
        font-size: 1.6rem;
        color: #55636d;
        background-color: #f6f6f6;
        border-radius: 1rem;
      }
    }
  }

  &.field-date {
    .date-picker {
      height: 6rem;
      width: 100%;
      background-color: #f6f6f6;
      border-radius: 1.6rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1.5rem;
      box-shadow: 0px 2px 6px #0000001a;

      p {
        font-size: 1.8rem;
        color: #8f8f8f;
      }

      mat-icon {
        position: relative;
        top: 0.15rem;
      }
    }
  }
  input::placeholder {
    color: var(--Core-Content-Tertiary) !important;
    font-weight: 500 !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
