:root {
  --Foundation-PrimaryA: #55636d;
  --Foundation-PrimaryB: #fff;
  --Foundation-Info: #cc9085;
  --Core-Border-Primary: #e2e6ee;
  --Core-Border-Secondary: #f1f3f9;
  --Core-Border-Selected: #55636d;
  --Core-Border-Disabled: #ccd2e0;
  --Core-Content-Primary: #b27e74;
  --Core-Content-Secondary: #626c83;
  --Core-Content-Tertiary: #96a0b6;
  --Core-Content-Disabled: #ccd2e0;
  --Core-Background-Primary: #FFF;
  --Core-Background-Secondary: #f9fafb;
  --Core-Background-Tertiary: #f8f9fc;
  --Extensions-Content-Danger: #D64F4F;
  --Extensions-Background-Brand: #cc9085;
  --Extensions-Background-LightBrand: #dae0e5;
  --Extensions-Background-Dark: #1c1f26;
  --Extensions-Background-Danger: #eb5757;
  --Extensions-Border-Danger: #f6b2b2;
  --Extensions-Background-Positive: #41C980;
  --Color-Primitives-Grey-400: #ccd2e0;
}