.mat-mdc-button {
  letter-spacing: 0 !important;
}

.about__tabs-container {
  [mat-align-tabs="center"] > .mat-mdc-tab-header .mat-mdc-tab-labels {
    gap: 1rem;
    @media (max-width: 768px) {
      gap: 0.75rem;
      flex-wrap: wrap;
    }
  }
  .mat-mdc-tab.mdc-tab {
    border-radius: 0.75rem !important;
    border: 1px solid var(--Core-Border-Primary) !important;
    background: var(--Core-Background-Secondary) !important;
  }
  .mat-mdc-tab.mdc-tab--active {
    border: 1px solid var(--Core-Content-Secondary) !important;
    background: var(--Core-Content-Secondary) !important;
  }
  .mat-mdc-tab .mdc-tab__text-label {
    color: var(--Core-Content-Primary) !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 2 !important;
    letter-spacing: 0 !important;
    @media (max-width: 768px) {
      font-size: 0.875rem !important;
      line-height: 1.625rem !important; /* 185.714% */
    }
  }
  .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
    color: var(--Foundation-PrimaryB) !important;
  }
  .mdc-tab-indicator {
    display: none;
  }
  .mdc-tab {
    padding: 0 0.75rem !important;
    min-width: fit-content !important;
  }
  .mat-mdc-tab-ripple {
    display: none;
  }
  .mat-mdc-tab-header-pagination-controls-enabled
    .mat-mdc-tab-header-pagination {
    display: none !important;
  }
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 3.125rem !important;
  background: var(--Foundation-PrimaryB) !important;
  box-shadow: 0px 213px 60px 0px rgba(28, 37, 64, 0),
    0px 136px 55px 0px rgba(28, 37, 64, 0),
    0px 77px 46px 0px rgba(28, 37, 64, 0.01),
    0px 34px 34px 0px rgba(28, 37, 64, 0.03),
    0px 9px 19px 0px rgba(28, 37, 64, 0.03),
    0px 0px 0px 0px rgba(28, 37, 64, 0.03) !important;
  @media (max-width: 768px) {
    background: #fff;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
      0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 1rem !important;
  }
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field
  .mdc-notched-outline__trailing {
  border-color: var(--Core-Border-Secondary) !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__trailing {
  border-color: var(--Core-Border-Selected) !important;
}

.mdc-notched-outline__trailing {
  border-left: 1px solid !important;
  border-right: none !important;
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0.75rem !important;
}

.mdc-notched-outline__leading {
  border-left: none !important;
  border-right: 1px solid !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
  border-bottom-left-radius: 0 !important;
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
  .mdc-notched-outline__notch {
  border-left: none !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline
  .mdc-notched-outline__trailing {
  border-width: 1px !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-floating-label--float-above {
  color: var(--Core-Content-Secondary) !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  line-height: 150%;
}

.mat-mdc-form-field {
  width: 100%;
}

.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__notch {
  min-width: fit-content !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-text-field__input {
  color: var(--Core-Content-Secondary) !important;
  font-size: 01rem !important;
  font-weight: 500 !important;
  line-height: 150%;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-text-field__input {
  caret-color: var(--Core-Border-Selected) !important;
}

.mdc-text-field--outlined {
  padding-right: max(
    16px,
    var(--mdc-outlined-text-field-container-shape)
  ) !important;
}

.mat-datepicker-toggle {
  color: var(--Core-Content-Secondary) !important;
}
.mat-datepicker-toggle-active {
  color: var(--Core-Content-Secondary) !important;
}

.mat-mdc-select {
  border: 1px solid var(--Core-Border-Secondary) !important;
  // height: 2.75rem;
  border-radius: 0.5rem;
  padding-top: var(--mat-form-field-container-vertical-padding);
  padding-bottom: var(--mat-form-field-container-vertical-padding);
  padding-right: max(16px, var(--mdc-outlined-text-field-container-shape));
  padding-left: max(
    16px,
    calc(var(--mdc-outlined-text-field-container-shape) + 4px)
  );
  @media (max-width: 768px) {
    // padding: 0.41rem 0.5rem 0.34rem !important;
  }
}
.mat-mdc-select-arrow {
  color: var(--Foundation-PrimaryA) !important;
}
.mat-mdc-select-value {
  color: var(--Core-Content-Secondary) !important;
}
.mat-mdc-option .mdc-list-item__primary-text {
  color: var(--Extensions-Background-Dark) !important;
  margin-right: 0 !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  line-height: 150%; /* 1.5rem */
}

div.mat-mdc-select-panel .mat-mdc-option:not(:last-child) {
  border-bottom: 1px solid var(--Core-Border-Secondary);
}

div.mat-mdc-select-panel {
  background-color: var(--Foundation-PrimaryB) !important;
  border-color: var(--Core-Border-Selected) !important;
  &::-webkit-scrollbar {
    display: none;
  }
}
.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
  .mdc-list-item__primary-text {
  color: var(--Foundation-Info) !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(
    .mat-mdc-option-multiple
  ) {
  background-color: var(--Core-Border-Primary) !important;
}

.mat-mdc-text-field-wrapper {
  border-radius: 0.5rem !important;
  background-color: var(--Foundation-PrimaryB) !important;
}

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled)
  .mdc-text-field__input {
  caret-color: var(--Extensions-Border-Danger) !important;
}
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled)
  .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled)
  .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled)
  .mdc-notched-outline__trailing {
  border-color: var(--Extensions-Border-Danger) !important;
}

.mat-mdc-option .mat-pseudo-checkbox-minimal {
  display: none;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}
div.mat-mdc-select-panel {
  border-radius: 0.5rem !important;
}

.mat-mdc-select-arrow-wrapper {
  background-image: url("./assets/images/chevron-down.svg");
  background-position: center;
  background-size: cover;
  width: 1.25rem;
}

.mat-mdc-select-arrow {
  opacity: 0;
}

.mat-mdc-select[aria-expanded="true"] .mat-mdc-select-arrow-wrapper {
  background-image: url("./assets/images/chevron-up.svg") !important;
}

.mat-mdc-form-field-icon-suffix {
  color: var(--Core-Content-Secondary) !important;
  font-size: 0.75rem !important;
  font-weight: 400;
  line-height: 150%; /* 1.125rem */
}

.phoneField {
  .mat-mdc-form-field-icon-suffix,
  [dir="rtl"] .mat-mdc-form-field-icon-prefix {
    border-left: 1px solid var(--Core-Border-Secondary);
    background: var(--Core-Background-Secondary);
    height: 54px;
    display: flex;
    align-items: center;
    padding: 0 0.5rem 0 1rem;
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
    margin-left: 1px;
  }
  .mdc-text-field--outlined {
    padding-left: 0 !important;
  }
}

.mat-mdc-select-placeholder,
.mat-mdc-form-field-textarea-placeholder {
  color: var(--Core-Content-Tertiary) !important;
  font-weight: 500 !important;
}

.field.field-check,
.field.field-radio,
.field.field-boolean {
  margin-bottom: 0 !important;
}

.initiatives-accordion {
  .mat-expansion-panel {
    background-color: var(--Core-Background-Primary) !important;
  }

  .mat-expansion-panel-header {
    @media (max-width: 768px) {
      border-radius: 0.5625rem !important;
    }
  }

  .mat-expansion-panel:not(.mat-expanded)
    .mat-expansion-panel-header:not([aria-disabled="true"]):hover {
    background-color: var(--Core-Background-Primary) !important;
  }

  .mat-expansion-panel-header-title {
    color: var(--Core-Content-Primary) !important;
    font-size: 1rem !important;
    font-weight: 700 !important;
    line-height: 2rem !important; /* 200% */
    @media (max-width: 768px) {
      font-size: 1.125rem !important;
      margin: 0 !important;
    }
  }

  .mat-expansion-panel-body {
    color: var(--Core-Content-Secondary) !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    line-height: 1.625rem !important; /* 185.714% */
    @media (max-width: 768px) {
      font-size: 1rem !important;
      padding: 0 1rem 1rem !important;
    }
  }
  .mat-expansion-indicator::after {
    color: var(--Core-Content-Primary) !important;
    padding: 0.375rem !important;
    @media (max-width: 768px) {
      padding: 0.3rem !important;
    }
  }
  .mat-expansion-panel:not([class*="mat-elevation-z"]) {
    box-shadow: none !important;
    border-radius: 1rem !important;
    border: 1px solid var(--Core-Border-Secondary) !important;
    margin-bottom: 0.75rem;
    @media (max-width: 768px) {
      margin-bottom: 1rem;
    }
  }

  .mat-content {
    margin-left: 1rem;
    white-space: nowrap;
  }
}

.mat-datepicker-content {
  background-color: var(--Extensions-Background-Brand) !important;
}
.mat-calendar-body-selected {
  background-color: var(--Foundation-PrimaryA) !important;
}

.mat-calendar-next-button::after {
  transform: translateX(2px) rotate(225deg) !important;
}
.mat-calendar-previous-button::after {
  transform: translateX(-1px) rotate(135deg) !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: transparent !important;
  box-shadow: none !important;
}
.mat-mdc-checkbox label {
  color: var(--Foundation-Info) !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  line-height: 150% !important; /* 1.5rem */
}
.mdc-checkbox__background {
  background-color: var(--Core-Background-Primary) !important;
  border-color: var(--Foundation-Info) !important;
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background,
.mdc-checkbox
  .mdc-checkbox__native-control:enabled:indeterminate
  ~ .mdc-checkbox__background,
.mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
  ~ .mdc-checkbox__background {
  background-color: var(--Foundation-Info) !important;
  border-color: var(--Foundation-Info) !important;
}
.mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__checkmark {
  color: var(--Core-Background-Primary) !important;
}

// .cdk-global-scrollblock{
//   top: 0 !important;
//   le: 0 !important;
// }
.digitalLibrary-container {
  .mdc-evolution-chip-set__chips {
    justify-content: center;
    gap: 0.5rem;
    @media (max-width: 768px) {
      gap: 1rem;
    }
  }

  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled)
    .mdc-evolution-chip__text-label {
    color: var(--Core-Content-Primary) !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 2rem !important; /* 200% */
    display: flex;
    align-items: center;
    gap: 0.63rem;
    cursor: pointer;
    @media (max-width: 768px) {
      font-size: 0.875rem !important;
      line-height: 1.625rem !important; /* 185.714% */
    }
  }

  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
    border-radius: 0.75rem !important;
    border: 1px solid var(--Core-Border-Primary) !important;
    background: var(--Core-Background-Secondary) !important;
    padding: 0.38rem 0.75rem !important;
    height: 3rem !important;
    cursor: pointer;
    &:hover {
      background: var(--Extensions-Background-LightBrand) !important;
      box-shadow: 0px 0px 0px 2px #e6f2ff !important;
      .mdc-evolution-chip__text-label {
        color: var(--Foundation-PrimaryA) !important;
      }
    }
    @media (max-width: 768px) {
      height: 2.625rem !important;
    }
  }

  .activeChip {
    .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
      background: var(--Core-Content-Secondary) !important;
      .mdc-evolution-chip__text-label {
        color: var(--Foundation-PrimaryB) !important;
      }
    }
  }
  .mat-mdc-button .mat-mdc-button-touch-target {
    height: fit-content !important;
  }

  .content-pagination {
    .mat-mdc-icon-button svg {
      transform: rotate(180deg);
    }
  }
  .mat-mdc-paginator-icon {
    fill: var(--Core-Content-Primary) !important;
  }
  .mat-mdc-paginator {
    color: var(--Core-Content-Primary) !important;
    background-color: var(--Core-Background-Primary) !important;
    font-size: 1rem !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 150% !important; /* 1.5rem */
  }
  .mat-button-toggle .mat-pseudo-checkbox {
    display: none;
  }

  .mat-mdc-select {
    max-width: fit-content;
  }
  .mat-mdc-form-field-infix,
  .mat-mdc-form-field,
  .mat-mdc-form-field-flex {
    width: fit-content !important;
  }

  .mat-mdc-select-trigger {
    gap: 0.5rem;
  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled)
    .mdc-line-ripple::after {
    border: none;
  }
  .mat-mdc-select-value-text {
    color: var(--Extensions-Background-Dark);
    font-size: 1rem;
    font-weight: 500;
    line-height: 150%; /* 1.5rem */
    @media (max-width: 768px) {
      font-size: 0.875rem;
    }
  }
}

.mat-mdc-menu-panel {
  background-color: var(--Core-Background-Primary) !important;
  width: 9.4375rem !important;
  transform: none !important;
  border-radius: 0.5rem !important;
}

.mat-menu-panel {
  transform: none !important;
}

.mat-mdc-dialog-container,
.mat-mdc-dialog-container .mdc-dialog__container,
.mat-mdc-dialog-container .mdc-dialog__surface {
  max-height: 95vh !important;
  overflow-y: hidden !important;
}

.cdk-overlay-connected-position-bounding-box {
  direction: ltr;
}
.material-icons {
  font-family: "Material Icons" !important;
}

.cdk-overlay-pane.mat-mdc-dialog-panel {
  margin: auto !important;
}
